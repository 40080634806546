.wrpr {
  grid-area: 7 / 1;
  padding-top: var(--spacing-6);

  @media (--tablet-l) {
    padding-top: 0;
    align-self: flex-end;
    grid-area: 2 / 1 / 3 / 3;
  }
}

.heading {
  margin: 0 0 var(--spacing-4);
}
