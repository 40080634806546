.wrapper {
  @media (--tablet-l) {
    grid-area: 2 / 3 / 3 / 4;
  }
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
}

.linkList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, max-content));
  gap: var(--spacing-5);

  & a {
    color: white;
  }
  @media (--tablet) {
    grid-template-columns: repeat(3, minmax(0, min-content));
  }
  @media (--desktop-l) {
    display: flex;
    gap: var(--spacing-5);
  }
}
