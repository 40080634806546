.wrapper {
  overflow: hidden;
  background: var(--footer-background-color);
  content-visibility: auto;

  [data-has-footer-sticky='true'] & {
    margin-bottom: 52px;
    @media (--tablet-l) {
      margin-bottom: 0;
    }
  }
}

.content {
  color: white;
  padding-top: var(--spacing-5);
  padding-bottom: var(--spacing-5);
  display: grid;
  @media (--tablet) {
    padding-top: var(--spacing-10);
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  @media (--tablet-s) {
    padding-top: var(--spacing-9);
    padding-bottom: var(--spacing-9);
  }

  @media (--desktop) {
    grid-template-columns: repeat(4, 1fr);
    padding-top: var(--spacing-16);
    padding-bottom: var(--spacing-5);
  }
}
