.trustpilotWrapper {
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
  @media (--tablet-l) {
    flex-direction: row;
  }
}

.ratingWrapper {
  display: flex;
  margin-bottom: var(--spacing-4);
  align-items: center;
  > img {
    margin-left: var(--spacing-1);
  }
}

& .ratingWrapper:first-child {
  @media (--tablet) {
    margin-right: var(--spacing-1);
  }
}
