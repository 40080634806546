.starsWrapper {
  display: flex;
  flex-direction: row;
  margin-left: var(--spacing-1);
}

.stars {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 1px;
  position: relative;
  background-color: #dcdce6;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    width: var(--width);
    background-color: #00b67a;
    max-width: 100%;
  }

  & .star {
    content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoCiAgICAgIGQ9Im03IDEwLjQ3OSAzLjA0MS0uNzcgMS4yNzEgMy45MTZMNyAxMC40NzlabTctNS4wNjJIOC42NDZMNyAuMzc1IDUuMzU0IDUuNDE3SDBsNC4zMzMgMy4xMjUtMS42NDYgNS4wNDEgNC4zMzQtMy4xMjUgMi42NjYtMS45MTZMMTQgNS40MTdaIgogICAgICBmaWxsPSIjZmZmIgogICAgLz4KICA8L3N2Zz4=');
    position: relative;
    width: 16px;
    height: 16px;
  }
}
