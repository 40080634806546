.wrapper {
}

.top {
  height: 64px;

  @media (--tablet) {
    height: 72px;
  }

  @media (--tablet-l) {
    height: 112px;
  }
}
