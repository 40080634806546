.groups {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  @media (--mobile) {
    flex: 1 1 100%;
    gap: 0;
  }
}
.group {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  & button {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &[data-expanded='true'] svg {
      transform: rotateX(180deg);
    }
    @media (--tablet) {
      cursor: unset;
    }

    & svg {
      font-size: var(--spacing-4);
      fill: white;
      width: var(--spacing-4);
      height: var(--spacing-4);
      transition: 0.4s;

      @media (--tablet) {
        transition: 0s;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
}

.menuList {
  list-style: none;
  padding: 0;

  margin: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
  &[data-expanded='true'] {
    margin-top: 20px;
    opacity: 1;
    visibility: visible;
    height: auto;
    transition: 0.4s opacity;
  }
  @media (--tablet) {
    opacity: 1;
    visibility: visible;
    height: auto;
    transition: 0.4s;
    margin-top: 20px;
  }
  @media (--desktop) {
  }
  .menuListItem {
    margin-bottom: 16px;

    & a {
      color: white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 0.05em;
        text-underline-offset: 2px;
      }
    }
  }
}

.langSelector {
  display: flex;
  white-space: nowrap;
  gap: var(--spacing-4);
  width: fit-content;
  flex-direction: column;

  @media (--desktop) {
    align-items: center;
    flex-direction: row;
  }

  .languageDropdown {
    cursor: pointer;
    & input {
      color: var(--color-championship-white);
      padding-left: var(--spacing-1);
    }
    & svg {
      color: var(--color-championship-white);
    }
  }
}
