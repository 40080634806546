.mainWrapper {
  /* border-top: 1px solid var(--color-neutral-300); */
  margin-top: 10px;
}

.trustpilotWrapper {
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
  gap: 8px;
  padding-top: 20px;

  @media (--tablet) and (max-width: 1023px) {
    flex-direction: row;
  }
}

.ratingWrapper {
  display: flex;
  align-items: center;
  color: var(--neutral-700-pressed-default-text);

  & h6 {
    margin: 0;
    padding: 0;
  }
}

.imageWrapper {
  display: flex;
  align-items: center;
}

.trustpilotImage {
  margin-left: var(--spacing-4);
  margin-right: var(--spacing-1);
}

& .ratingWrapper:first-child {
  @media (--tablet) {
    margin-right: var(--spacing-1);
  }
}
